
.navbar {
  background-color: #dad8e7 !important; /* Dark blue background */
  padding: 10px 20px; /* Add some spacing for aesthetics */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  
}


/* Styling for navbar brand (logo + text) */
.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  height: 80px; /* Adjust logo height */
  margin-right: 20px; /* Space between logo and text */
}

.navbar-brand span {
  font-size: 20px; /* Adjust font size for text */
  font-weight: bold; /* Bold text for emphasis */
  color: #ff6600; /* Orange color for brand text */
}


/* Navbar link styling */
.navbar-nav .nav-link {
  color: #485D88; /* Light color for links to contrast with dark background */
  font-size: 16px; /* Adjust font size as needed */
  font-weight: 500; /* Medium weight for links */
  transition: color 0.3s ease;
}

/* Active and hover link styling */
.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: #ff6600; /* Orange color for hover and active state */
  text-decoration: none; /* Remove underline */
}


/* Dropdown menu styling */
.dropdown-menu {
  background-color: #14289e; /* Dark blue background */
  border: 1px solid #e0e0e0; /* Light border for separation */
}

.dropdown-item {
  color: #f8f9fa; /* Light color for dropdown links */
  transition: color 0.3s ease, background-color 0.3s ease;
}

.dropdown-item:hover {
  color: #ff6600; /* Orange color for hover */
  background-color: #f8f9fa; /* Light grey background */
}


/* Adjust spacing for navbar items */
.nav-item {
  margin-right: 20px;
}

/* Navbar.css */
.navbar-toggler-icon {
  background-color: rgba(255, 255, 255, 0.815) !important;
  border-radius: 5%;
}


/* Responsive adjustments */
@media (min-width: 300px) and (max-width: 600px) {
  .navbar {
    padding: 5px 10px; /* Reduce padding for smaller screens */
   
  }

  .navbar-brand img {
    height: 30px; /* Smaller logo for mobile */
  }

  .nav-item {
    margin-right: 10px; /* Reduce spacing */
  }
}

@media (min-width: 600px) and (max-width: 1000px) {
  .navbar-brand img {
    height: 40px; /* Adjust logo size for medium screens */
  }
}
@media (min-width: 1000px) and (max-width: 1500px) {
    .navbar {
      height: 110px;
    }
}
