/* Overall Page Layout */
.contact-page {
  font-family: 'Arial', sans-serif;
  margin: 20px auto;
  width: 100%;
  max-width: 1200px;
  background-color: #f5f5f5; /* Slightly lighter background */
  padding: 30px;
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1); /* More intense shadow */
}

/* Header Section */
.contact-header {
  background: linear-gradient(to right, #6a1b9a, #8e24aa); /* Adjusted gradient for more contrast */
  color: white;
  padding: 50px 0;
  margin-bottom: 40px;
  border-radius: 12px;
}

.contact-header h1 {
  font-size: 3rem;
  margin: 0;
  font-weight: 700;
}

.contact-header p {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 500;
}

/* Contact Info Section */
.contact-info {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 50px;
  flex-wrap: wrap; /* Allow the boxes to wrap on smaller screens */
  align-items: flex-start; /* Align items to the top */
}

.info-box {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  text-align: left; /* Align text to the left */
  display: flex;
  align-items: center; /* Vertically center the content */
  gap: 15px; /* Space between icon and text */
  transition: all 0.3s ease;
  width: 28%; /* Set the width to 32% so there is space between them */
  margin-bottom: 20px; /* Add some space between the boxes */
}

.info-box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
}

.icon-text {
  display: flex;
  align-items: center; /* Align icon and text vertically */
  gap: 15px;
}

.icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon img {
  width: 50px;
  height: 50px;
  transition: transform 0.3s ease;
  }

.icon img:hover {
  transform: scale(1.1); /* Slightly enlarge the icon on hover */
}

.text {
  color: #333;
}

.text h3 {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #6a1b9a;
}

.text p {
  font-size: 1rem;
  margin-top: 5px;
  font-weight: 400;
  color: #555;
  line-height: 1.5rem;
}

/* Contact Form Section */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.form-row input,
.form-row textarea {
  width: 100%; /* Makes all form inputs and the textarea span full width */
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 1rem;
}

/* Adjust the Phone Input Size */
.form-row input[type="tel"] {
  max-width: 390px; /* Decrease the width of the phone input */
  width: 100%; /* Ensure it takes up the full width available in its row */
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-size: 1rem;
}

.form-row input {
  flex: 1; /* Makes sure that inputs are flexible and fit the row */
}

textarea {
  min-height: 150px; /* Makes sure the textarea is tall enough */
}



/* Mobile Responsiveness */
@media (max-width: 768px) {
  .contact-info {
    flex-direction: column;
    align-items: center;
  }

  .info-box {
    width: 100%;
    margin-bottom: 20px;
    text-align: center; /* Center the text and icon on mobile */
  }

  .icon-text {
    justify-content: center; /* Center the icon and text horizontally */
  }
}

@media (min-width: 300px) and (max-width: 768px){
  .contact-page {
    margin-left: 0%;
    width: 100%;
  }
  
}

@media (min-width: 600px) and (max-width: 1200px){
  .contact-page {
    margin-left: 0%;
    width: 93%;
  }
  .info-box {
    width: 95%;
    margin-bottom: 20px;
    text-align: center; /* Center the text and icon on mobile */
  }
}